<template>
  <div class="zh">
    <div class="video-list">
      <div class="video-item" v-for="(item, index) in data.videoList" :key="index">
        <svg-icon name="play" class-name="w-10vw h-10vw" @click="handlerPlay(item)" />
        <img :src="$img + item.thumb" alt="" class="cover">
        <div class="title">{{ item.title }}</div>
      </div>
    </div>
  </div>

  <!-- 分页 -->
  <div class="paging">
    <el-pagination background layout="pager" :page-size="10" :total="data.total" @current-change="handlerChange" />
  </div>

  <!-- 视频播放框 -->

  <el-dialog v-model="data.outerVisible" width="13rem">
    <video controls :src="$img + data.videoLink" class="video-component" />
  </el-dialog>
</template>

<script setup>
import { apiVideoList } from "@/api/list.js"
import { reactive, onMounted } from 'vue'
let data = reactive({
  page: 1,
  limit: 20,
  total: 0, // 总条数
  videoList: [], // 新闻数据列表
  outerVisible: false,
  videoLink: '', // 视频播放链接
})

const handlerNews = async () => {
  let { data: res } = await apiVideoList({
    page: data.page,
    limit: data.limit,
    is_en: 3,
    type: 1
  })
  data.videoList = res.data
  data.total = res.total
}

// 分页条数改变
const handlerChange = (num) => {
  data.page = num
  handlerNews()
}

// 点击播放视频
const handlerPlay = (item) => {
  data.videoLink = item.url
  data.outerVisible = true

}

onMounted(() => {
  handlerNews()
})
</script>

<style lang="scss">
// 分页器样式
.paging {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: .4rem 0 .6rem 0px;

  &:deep(.el-pager) {

    .number,
    .more {
      width: .82rem;
      height: .84rem;
      background: #ffffff;
      border-radius: .08rem .08rem .08rem .08rem;
      opacity: 1;
      border: .02rem solid #aaaaaa;

      &:hover {
        color: #a6291a;
      }
    }

    .is-active {
      width: .84rem;
      height: .84rem;
      background: #a6291a !important;
      border-radius: .08rem .08rem .08rem .08rem;
      opacity: 1;
      border: .02rem solid #a6291a;
      color: #fff !important;
    }
  }
}

// 在pc端
@media screen and (min-width: $media) {
  .zh {
    width: 8.8203rem;

    .video-list {
      width: 8.8203rem;
      display: grid;
      grid-template-columns: repeat(2, 4.21875rem);
      justify-content: space-between;

      .video-item {
        position: relative;
        width: 4.21875rem;
        min-width: 4.1796875rem;
        margin: 0.234375rem 0 0 0;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        transition: .3s ease;
        cursor: pointer;

        &:hover {
          box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px;
        }

        .svg-box {
          position: absolute;
          left: 50%;
          top: 33%;
          transform: translateX(-50%);
          width: .5rem;
          height: .5rem;
        }

        .cover {
          width: 100%;
          height: 2.171875rem
        }

        .title {
          margin: 0.078125rem 0 0 0;
          padding: 0;
          width: 100%;
          height: 0.578125rem;
          background: linear-gradient(267deg, #ffbda5 0%, #e34f1b 63%, #de3a00 100%);
          background-size: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #fff;
          font-size: .15625rem;
        }
      }
    }
  }

  .video-component {
    width: 100%;
    height: 5.4688rem;
    margin-top: -0.1rem;

    &::-webkit-media-controls-start-playback-button {
      display: block;
      font-size: initial;
    }
  }
}

// 在移动端
@media screen and (max-width: $media) {
  .zh {
    width: 100%;

    .video-list {
      width: 100%;
      display: flex;
      flex-direction: column;

      .video-item {
        position: relative;
        width: 100%;
        height: 8rem;
        margin-bottom: .3rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        transition: .3s ease;

        .svg-box {
          position: absolute;
          left: 50%;
          top: 33%;
          transform: translateX(-50%);
          width: 1rem;
          height: 1rem;
        }

        .cover {
          flex: 1;
          width: 100%;
          height: 5.2rem;
        }

        .title {
          padding: 0;
          width: 100%;
          height: 1.2rem;
          background: linear-gradient(267deg, #ffbda5 0%, #e34f1b 63%, #de3a00 100%);
          background-size: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #fff;
          font-size: .32rem;
          margin-top: .4rem;
        }
      }

      & .video-item:last-child {
        margin-bottom: 0px;
      }
    }

    .el-dialog__header {
      display: none;
    }

    .el-dialog__body {
      padding: 0px;
    }

    .video-component {
      width: 100%;
      height: 6rem;

      &::-webkit-media-controls-start-playback-button {
        display: block;
        font-size: initial;
      }
    }
  }
}
</style>